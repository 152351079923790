<template>
  <div v-if="ready">
    <div v-for="(field,name) in this.orderedFields" :key="name">
      <div  v-if="field.constraints.includes('isTagsArray')">
        <CITagsView :label=getLabel(field) :tags="formData[field.name]"/>
      </div>
      <div v-else-if="field.constraints.includes('isCustomFieldsArray')">
        <CICustomFieldsView
          :customFields="formData[field.name]"
        />
      </div>
      <div v-else-if="field.constraints.includes('isMetadataMap')">
        <ZqCardRow
          v-if="formData.metadata"
         :fieldName="field.name"
         :parentClass="modelFields.baseFields.objectType"
         :label=getLabel(field)
         :fieldConstraints=field.constraints
         :content=formData.metadata
        />
      </div>
      <div v-else-if="field.constraints.includes('isModel')">
        <ZqCardRow
          v-if="formData.rewardType"
         :fieldName="field.name"
         :parentClass="modelFields.baseFields.objectType"
         :label=getLabel(field)
         :fieldConstraints=field.constraints
         :content=formData.rewardType.key
        />
        <ZqActionTypeAdjustmentFactorsView
          v-if="formData.actionTypeAdjustmentFactors"
          :formData=formData
        />
      </div>
      <div v-else-if="field.name === 'instantWinType'">
        <ZqCardRow
          :fieldName="field.name"
          :parentClass="modelFields.baseFields.objectType"
          :label=getLabel(field)
          :fieldConstraints="field.constraints"
          :content="formData[field.name] === 1 ? 'Spinner' : 'Scratchcard'"
        />
      </div>
      <div v-else-if="field.name === 'unitOfMeasure'">
        <ZqCardRow
          :fieldName="field.name"
          :parentClass="modelFields.baseFields.objectType"
          :label=getLabel(field)
          :fieldConstraints="field.constraints"
          :content="uom"
        />
      </div>
      <div v-else>
        <ZqCardRow
          :fieldName="field.name"
          :parentClass="modelFields.baseFields.objectType"
          :label=getLabel(field)
          :fieldConstraints="field.constraints"
          :content=formData[field.name]
          :html="htmlFields.includes(field.name)"
          :entityTypePreview="entityTypePreview"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CITagsView from '@/shared/components/CITagsView';
import ZqActionTypeAdjustmentFactorsView from '@/shared/components/ZqActionTypeAdjustmentFactorsView.vue';
import ZqCardRow from '@/shared/UI/ZqCardRow';
import { mapActions } from 'vuex';
export default {
  name:'ZqFieldsView',
  components: {
    CITagsView,
    ZqCardRow,
    ZqActionTypeAdjustmentFactorsView
  },
  props: {
    texts: {},
    formData: {},
    modelFields: {},
    model: '',
  },
  inject: {
    stepKeys: {
      from: 'stepKeys',
      default: [],
    },
  },
  data(){
    return {
      ready: false,
      htmlFields: ['description', 'termsAndConditions'],
      orderedFields: {},
      labelTexts: {},
      entityTypePreview: null,
      uom: '',
    }
  },
  created() {
    this.labelTexts = this.texts;
    this.initialize();
  },
  methods: {
    ...mapActions('contests', ['handleGetContestsByQuery']),
    ...mapActions('unitsOfMeasure', ['handleGetUnitsOfMeasure_item']),
    async initialize() {
      const achievementExcludeFields = ['achievementDependencies', 'scheduling', 'memberTagsFilter'];
      const isAchievement = this.formData.constructor.name === 'Achievement'

      if (Object.keys(this.formData).includes('entityType') && Object.keys(this.formData).includes('entityId')) {
        this.setEntityIdLink()
      }

      if (this.formData.constructor.name === 'Competition') {
        delete this.modelFields.baseFields.fields.numberOfGroupStages
      }

      const arr = isAchievement
          ? Object.values(this.modelFields.baseFields.fields).filter(item => !achievementExcludeFields.includes(item.name))
          : Object.values(this.modelFields.baseFields.fields);

      const filteredArr =  arr.filter(item => Object.keys(this.formData).includes(item.name));

      this.orderedFields = this.orderFieldsByDisplayOrder(filteredArr);

      const statusIdx = this.orderedFields.findIndex(o => o.name === 'status');
      if (statusIdx !== -1) {
        const statusField = this.orderedFields.splice(statusIdx, 1)[0];
        this.orderedFields.unshift(statusField);
      }

      if (this.formData.hasOwnProperty('unitOfMeasure') && this.formData.unitOfMeasure) {
        if (this.model === 'event') {
          this.uom = this.formData.unitOfMeasure;
        } else {
          const uom = await this.handleGetUnitsOfMeasure_item([this.formData.unitOfMeasure], 1, 0);
          this.uom = uom[0]?.name;
        }
      }

      if (this.formData.hasOwnProperty('eventRefId')) {
        const idx = this.orderedFields.findIndex(o => o.name === 'unitOfMeasure');
        if (idx !== -1) {
          const unitOfMeasureField = this.orderedFields.splice(idx, 1)[0];
          const sourceValueIdx = this.orderedFields.findIndex(o => o.name === 'sourceValue');
          if (sourceValueIdx !== -1) {
            this.orderedFields.splice(sourceValueIdx + 1, 0, unitOfMeasureField);
          }
        }
      }

      this.ready = true;
    },
    async setEntityIdLink() {
      this.entityType = this.formData.entityType;

      switch (this.entityType) {
        case 'Contest': {
          const data = await this.handleGetContestsByQuery({
            queryRequest: {
              must: [{
                queryField: 'id',
                queryValues: [this.formData.entityId]
              }],
              skip: 0,
              limit: 1,
            }
          });
          this.entityTypePreview = {
            name: `PreviewContest`,
            params: { id: data[0].competitionId, contestId: data[0].id }
          }
        }
          break;
        case 'Achievement': {
          this.entityTypePreview = {
            name: `PreviewAchievement`,
            params: { id: this.formData.entityId }
          }
        }
          break;
        case 'Competition': {
          this.entityTypePreview = {
            name: `PreviewCompetition`,
            params: { id: this.formData.entityId }
          }
        }
          break;
      }
    },
    orderFieldsByDisplayOrder(fieldsArray) {
      return fieldsArray.sort(function (a, b) {
        const keyA = a.displayOrder;
        const keyB = b.displayOrder;

        const requiredA = a.constraints.includes('required');
        const requiredB = b.constraints.includes('required');

        if (requiredA && !requiredB) return -1;
        if (requiredB && !requiredA) return 1;

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        return 0;
      });
    },
    getLabel(field){
      let labelKey = field.name + 'Label';

      let found = this.labelTexts[labelKey];
      if(found) labelKey = found;

      if(field.constraints.includes('required'))
        labelKey = labelKey+"*";

      return labelKey;
    },
  },
};
</script>
